import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

//import {InlineShareButtons} from 'sharethis-reactjs';
//
// NOTE - this is c/p from sharethis-reactjs package,
// which wasn't updating (had to add componentDidUpdate method, see below)

// load project wrapper
const load = function(component, product) {

  // load config
  let config = component.props.config || {enabled: true};
  config = JSON.parse(JSON.stringify(config));

  // load buttons
  const _onShareThisLoaded = window.onShareThisLoaded;
  let onload = () => {
    if (!onload.complete) {
      if (!config.id) {
        const id = 'sharethis-' + Date.now();
        config.id = id;
      }
      if (component.buttons.current) {
        component.buttons.current.id = config.id;
        window.__sharethis__.load(product, config);
      }
      if (_onShareThisLoaded) {
        _onShareThisLoaded();
      }
      onload.complete = true;
    }
  };
  window.onShareThisLoaded = onload;

  // load sharethis.js
  if (document.getElementById('sharethis-js')) {
    if (window.__sharethis__) {
      window.onShareThisLoaded();
    }
  } else {
    const script = document.createElement("script");
    script.setAttribute('id', 'sharethis-js');
    const params = {
      property: config.property || '',
      product: product,
      source: 'reactjs'
    }
    const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    script.src = "https://platform-api.sharethis.com/js/sharethis.js?" + query;
    script.async = true;
    document.body.appendChild(script);
  }
}

class InlineShareButtons extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = React.createRef();
  }

  componentDidMount() {
    load(this, 'inline-share-buttons');
  }

  componentDidUpdate() {
    load(this, 'inline-share-buttons');
  }

  render () {
    return (
      <div ref={this.buttons} />
    );
  }
};

export default function ShareThis({url}) {
  const element = (
    <div className={'ShareThis'}>
      <InlineShareButtons
        config={{
          alignment: 'center',  // alignment of buttons (left, center, right)
          color: 'social',      // set the color of buttons (social, white)
          enabled: true,        // show/hide buttons (true, false)
          font_size: 16,        // font size for the buttons
          labels: 'cta',        // button labels (cta, counts, null)
          language: 'en',       // which language to use (see LANGUAGES)
          networks: [           // which networks to include (see SHARING NETWORKS)
            'facebook',
            'messenger',
            'twitter',
            'sms',
            'email',
            'whatsapp',
            'reddit',
            'print',
          ],
          padding: 12,          // padding within buttons (INTEGER)
          radius: 4,            // the corner radius on each button (INTEGER)
          size: 40,             // the size of each button (INTEGER)

          // OPTIONAL PARAMETERS
          url: url,
          //description: 'custom text',       // (defaults to og:description or twitter:description)
          //title: 'custom title',            // (defaults to og:title or twitter:title)
          //message: 'custom email text',     // (only for email sharing)
          //subject: 'custom email subject',  // (only for email sharing)
          //username: 'custom twitter handle' // (only for twitter sharing)
        }}
      />
    </div>
  );
  const portals = Array.from(document.getElementsByClassName('share-this-root'))
    .map(root => ReactDOM.createPortal(element, root));
  return portals;
}

