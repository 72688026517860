import React from 'react';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {cycle} from 'utils';

import './index.css';

export default class MyLineChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    keys: PropTypes.array.isRequired,
    scale: PropTypes.string.isRequired,
    colors: PropTypes.array.isRequired,
  };

  constructor() {
    super();
    this.state = {};
  }

  getColors() {
    const {colors, keys} = this.props;
    const colorCycle = cycle(Array.from(colors))
    const colorsByKey = {};
    keys.forEach(key => colorsByKey[key] = colorCycle.next().value);
    return colorsByKey;
  }

  getMaxValue(data) {
    let max = 0;
    data.forEach(datum => {
      Object.entries(datum).forEach(([key, _val]) => {
        if (key === 'timestamp') {
          return;
        }
        const val = Number(_val);
        if (val > max) {
          max = val;
        }
      });
    });
    return max;
  }

  onDotEnter(point) {
    this.setState({
      activePoint: point,
    });
  }

  onDotLeave() {
    this.setState({
      activePoint: null,
    });
  }

  getTooltip() {
    const {activePoint} = this.state;
    if (activePoint) {
      return (
        <Tooltip content={() => {
            return (
              <div className={'MyLineChart--custom-tooltip'}>
                <div style={{paddingBottom: '4px'}}>
                  {activePoint.payload.timestamp}
                </div>
                <span style={{color: activePoint.fill}}>
                  {activePoint.dataKey}: {activePoint.value}
                </span>
              </div>
            );
          }}
        />
      );
    }
    return (
      <Tooltip
        isAnimationActive={true}
        itemSorter={
          item => Number(item.payload[item.name]) * -1
        } />
    );
  }

  render() {
    const {data, keys} = this.props;
    const colorsByKey = this.getColors();
    return (
      <div className={'MyLineChart'}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 20,
              left: 10,
              bottom: 5,
            }}>
            <Legend
              height={100}
              wrapperStyle={{paddingBottom: '10px', overflow: 'scroll'}}
              verticalAlign={'top'} />
            <CartesianGrid
              stroke={'#f5f5f5'} />
            <XAxis
              dataKey={'timestamp'} />
            <YAxis
              domain={[
                this.props.scale === 'log' ? 'auto' : 0,
                this.getMaxValue(data)
              ]}
              scale={this.props.scale}
            />
            {this.getTooltip()}
            {keys.map((key, idx) => (
              <Line
                activeDot={{
                  onMouseOver:this.onDotEnter.bind(this),
                  onMouseOut:this.onDotLeave.bind(this),
                }}
                dataKey={key}
                dot={false}
                key={key}
                stroke={colorsByKey[key]}
                strokeWidth={2}
                type={'monotone'} />
             ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

