import React from 'react';
import PropTypes from 'prop-types';

import CheckSheet from './checksheet';

import './index.css';

export default class Form extends React.Component {
  static propTypes = {
    keys: PropTypes.array.isRequired,
    locationSelectedValues: PropTypes.array.isRequired,
    periodSelectedValue: PropTypes.number.isRequired,
    scaleSelectedValue: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      locationSelectedValues: props.locationSelectedValues,
      periodSelectedValue: props.periodSelectedValue,
      scaleSelectedValue: props.scaleSelectedValue,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  makeOptions(keys) {
    return keys.map(key => ({key}));
  }

  makePeriodOption(value) {
    let key = 'All time';
    if (value !== 0) {
      key = `Last ${value} days`;
    }
    return {
      key,
      value,
    };
  }

  render() {
    const periodOptions = [3,7,10,14,21,30,60,0].map(this.makePeriodOption);
    const locationOptions = this.makeOptions(this.props.keys);
    const scaleOptions = this.makeOptions(['log', 'linear']);
    return (
      <div>
        <div>
          <div className={'clearfix'}>
            <div className={'left'}>
              <span>{'Period'}</span>
            </div>
            <div className={'period-selector right'}>
              <select
                onChange={(evnt) => this.onPeriodChange(evnt)}
                value={this.state.periodSelectedValue}>
                {periodOptions.map(opt => (
                  <option key={opt.key} value={opt.value}>{opt.key}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <div className={'clearfix'}>
            <div className={'left'}>
              <span>{'Scale'}</span>
            </div>
            <div className={'scale-selector right'}>
              {scaleOptions.map(option => (
                <label key={option.key}>
                  <input
                    checked={option.key === this.state.scaleSelectedValue}
                    name={'scale'}
                    value={option.key}
                    onChange={(evnt) => this.onScaleChange(evnt)}
                    type={'radio'} />
                  <span>{option.key}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className={'location-selector'}>
          <CheckSheet
            heading={'Country / State'}
            options={locationOptions}
            onSubmit={(locationSelectedValues) => this.onLocationChange(locationSelectedValues)}
            selectedValues={this.props.locationSelectedValues}
          />
        </div>
      </div>
    );
  }

  onLocationChange(locationSelectedValues) {
    this.setState({locationSelectedValues}, this.onSubmit)
  }

  onPeriodChange(evnt) {
    this.setState({periodSelectedValue: Number(evnt.target.value)}, this.onSubmit);
  }

  onScaleChange(evnt) {
    this.setState({scaleSelectedValue: evnt.target.value}, this.onSubmit);
  }

  onSubmit() {
    this.props.onSubmit({
      scale: this.state.scaleSelectedValue,
      period: this.state.periodSelectedValue,
      selectedLocations: this.state.locationSelectedValues,
    });
  }
}

