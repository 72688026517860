import React from 'react';

import moment from 'moment';
import Papa from 'papaparse';
import queryString from 'query-string';
import zip from 'lodash.zip';

import Chart from './components/line-chart';
import Form from './components/form';
import ShareThis from './components/share-this';
import {cycle} from 'utils';

import './App.css';

const JHU_DEATHS = "https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_time_series/time_series_covid19_deaths_global.csv";
const JHU_CONFIRMED = "https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_time_series/time_series_covid19_confirmed_global.csv";
const NYT_COUNTIES = "https://raw.githubusercontent.com/nytimes/covid-19-data/master/us-counties.csv";
const NYT_STATES = "https://raw.githubusercontent.com/nytimes/covid-19-data/master/us-states.csv";

const colors =[
  'red',
  'orange',
  'green',
  'blue',
  'purple',
  'violet',
  '#3b3b3b',
];
const colorCycle = cycle(Array.from(colors));

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      confirmed: {
        header: null,
        rows: {},
        data: [],
      },
      deaths: {
        header: null,
        rows: {},
        data: [],
      },
      allLocations: [],
      selectedLocations: [
        'Italy',
        'Spain',
        'France',
        'US',
        'China - Hubei',
      ],
      period: 0,
      scale: 'log',
      loading: true,
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  getDeserializedState(props) {
    const {search} = props.location;
    let paramString;
    if (search.indexOf('|') > -1) {
      paramString = search.replace(/--/g, ' - ').replace(/_/g, ' ');
    } else {
      paramString = decodeURIComponent(search).replace(/--/g, ' - ').replace(/_/g, ' ');
    }
    if (paramString) {
      const deserializedState = {};
      const params = queryString.parse(paramString, {
        parseNumbers: true,
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      });
      const {
        period,
        scale,
        locations,
      } = params;
      if (period !== undefined) {
        deserializedState.period = period;
      }
      if (scale !== undefined) {
        deserializedState.scale = scale;
      }
      if (locations !== undefined) {
        if (typeof locations === 'string') {
          deserializedState.selectedLocations = [locations];
        } else {
          deserializedState.selectedLocations = locations;
        }
      } else {
        deserializedState.selectedLocations = [];
      }
      return deserializedState;
    }
  }

  getSerializedState(state) {
    const locations = state.selectedLocations.map(loc => loc.replace(/ - /g, '--').replace(/ /g, '_'));
    return '?' + encodeURIComponent(queryString.stringify({
      period: state.period,
      scale: state.scale,
      locations,
    }, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
    }));
  }

  componentDidMount() {
    const initialState = this.getDeserializedState(this.props) || {};
    getData().then(data =>
      this.setState(Object.assign(data, initialState), () =>
        this.navigate(this.state)
      ));
  }

  componentWillReceiveProps(props) {
    const initialState = this.getDeserializedState(props);
    this.updateState(initialState);
  }

  updateState(configuration) {
    const newState = {
      ...this.state,
      ...configuration,
      ...{loading: false},
    };

    const {
      period,
      selectedLocations,
    } = newState;

    ['deaths', 'confirmed'].forEach(seriesKey => {
      const {header, rows} = newState[seriesKey];

      let offset = 0;
      if (period !== 0) {
        offset = header.length - period;
      }
      const timestamps = header.slice(offset);

      const data = timestamps.map((timestamp, idx) => {
        const datum = {
          timestamp,
        };
        selectedLocations.forEach(_location => {
          datum[_location] = rows[_location][offset + idx];
        });
        return datum;
      });

      newState[seriesKey] = {
        data,
        header,
        rows,
      };
    });
    this.setState(newState);
  };

  onSubmit(configuration) {
    this.navigate(configuration);
  }

  navigate(configuration) {
    this.props.history.push(this.getSerializedState(configuration))
  }

  makeSummary(locations, deaths, confirmed) {
    if (!(deaths.data.length && confirmed.data.length)) {
      return [];
    }

    return locations.map(_location => {
      const deathCount = getLastValue(deaths.rows[_location]);
      const confirmedCount = getLastValue(confirmed.rows[_location]);
      return [_location, deathCount, confirmedCount];
    });
  }

  render() {
    const {
      confirmed,
      deaths,
      allLocations,
      selectedLocations,
      period,
      scale,
      loading,
    } = this.state;

    let sortedLocations = selectedLocations;
    if (deaths.data.length) {
      const lastDatum = getLastValue(
        deaths.data,
        (datum) => selectedLocations.map(loc => datum[loc]).every(x => x !== undefined)
      );
      sortedLocations = selectedLocations
        .sort((a, b) => Number(lastDatum[b]) - Number(lastDatum[a]))
    }

    const summaryData = this.makeSummary(sortedLocations, deaths, confirmed);

    if (loading) {
      return null;
    }

    return (
      <div className="App">
        <ShareThis url={window.location.href} />
        <span className={'App-Header h1'}>The Coronavirus Curve</span>
        <div className="content">
          <div className={'App-Form'}>
            <Form
              periodSelectedValue={period}
              locationSelectedValues={selectedLocations}
              scaleSelectedValue={scale}
              keys={allLocations}
              onSubmit={this.onSubmit} />
          </div>

          <span className="App-Header h2">Deaths</span>
          <div className={'App-Chart-container'}>
            <div>
              <Chart
                colors={colors}
                data={deaths.data}
                keys={sortedLocations}
                scale={scale} />
            </div>
          </div>

          <div className={'spacer'}></div>

          <span className="App-Header h2">Confirmed Cases</span>
          <div className={'App-Chart-container'}>
            <div>
              <Chart
                colors={colors}
                data={confirmed.data}
                keys={sortedLocations}
                scale={scale} />
            </div>
          </div>

          <div className={'spacer'}></div>

          <span className="App-Header h2">Summary</span>
          <div className={'App-SummaryTable-container'}>
            <div className={'App-SummaryTable'}>
              <div>
                <span></span>
                <span>Deaths</span>
                <span>Confirmed Cases</span>
              </div>
              <div>
                {summaryData.map(([_location, deaths, confirmed]) => (
                  <div
                    key={`summaryData--${_location}`}
                    style={{color: colorCycle.next().value}}>
                    <span>{_location}</span>
                    <span>{deaths}</span>
                    <span>{confirmed}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={'spacer'}></div>
        </div>
      </div>
    );
  }
}

function makeName(country, state, county) {
  if (country && state && county) {
    return `${country} - ${state} - ${county}`
  } else if (country && state) {
    return `${country} - ${state}`
  } else if (country) {
    return country;
  } else if (state) {
    return state;
  }
  return '';
}

function parseRawData(rawData) {
  return Papa.parse(rawData).data;
}

function formatJHURows(_rows) {
  const data = {};

  const header = _rows[0];
  const rows = _rows.slice(1);
  const timestamps = header.slice(4);

  for (const row of rows) {
    const _location = makeName(row[1], row[0]);
    data[_location] = row.slice(4);
  }

  return {timestamps, data};
}

function formatNYTRows(_rows, byCounty = false) {
  const deaths = {};
  const confirmed = {};

  const rows = _rows.slice(1)

  const confirmedIndex = byCounty ? 4: 3;
  const deathsIndex = byCounty ? 5: 4;
  const makeLocationName = byCounty ?
    (row) => makeName('US', row[2], row[1]) :
    (row) => makeName('US', row[1]);

  // create sets of timestamps, locations
  // reformat row timestamp, location
  const timestampSet = new Set();
  const locationSet = new Set();
  for (const row of rows) {
    const timestamp = moment(row[0], 'YYYY-MM-DD').format('M/DD/YY');
    timestampSet.add(timestamp);
    row[0] = timestamp;

    const _location = makeLocationName(row);
    locationSet.add(_location);
    row[1] = _location;
  }

  // initialize empty ts series for each location, for both death and confirmed
  const timestamps = Array.from(timestampSet)
    .sort((a, b) => a.localeCompare(b))

  for (const _location of locationSet) {
    deaths[_location] = Array.from({length: timestamps.length}, x => '');
    confirmed[_location] = Array.from({length: timestamps.length}, x => '');
  }

  // index timestamps for faster lookup (than indexOf)
  const tsIndexes = {};
  timestamps.forEach((ts, idx) => tsIndexes[ts] = idx)

  // loop through all rows, build up data
  for (const row of rows) {
    const timestamp = row[0];
    const _location = row[1];

    const tsIndex = tsIndexes[timestamp];

    confirmed[_location][tsIndex] = row[confirmedIndex];
    deaths[_location][tsIndex] = row[deathsIndex];
  }

  return {timestamps, deaths, confirmed};
}

function mergeFormattedData(series) {
  const [jhuDeaths, jhuConfirmed, nytStates, nytCounties] = series;
  const startEndTimestamps = series
    .map(({timestamps}) => [timestamps[0], timestamps[timestamps.length-1]])
    .map(([start, end]) => [moment(start), moment(end)])

  const [maxStart, maxEnd] = startEndTimestamps
    .reduce(([accum_start, accum_end], [next_start, next_end]) =>
      [
        moment.max(accum_start, next_start),
        moment.max(accum_end, next_end)
      ]
    )

  const offsets = startEndTimestamps.map(([start, end]) =>
    [maxStart.diff(start, 'days'), maxEnd.diff(end, 'days')]
  )

  for (const [{timestamps, deaths, confirmed}, [startOffset, endOffset]] of zip(series, offsets)) {
    // remove timestamps/values that precede max start
    for (let ii = 0; ii < startOffset; ii++) {
      timestamps.shift();
    }
    [deaths, confirmed].filter(x => x).forEach(group =>
      Object.values(group).forEach(row => {
        for (let ii = 0; ii < startOffset; ii++) {
          row.shift();
        }
      })
    )

    // add timestamps and null values for series ending before max end
    const lastTimestamp = moment(timestamps[timestamps.length-1])
    for (let ii = 0; ii < startOffset; ii++) {
      timestamps.push(lastTimestamp.add(1, 'days').format('M/DD/YY'))
    }
    [deaths, confirmed].filter(x => x).forEach(group =>
      Object.values(group).forEach(row => {
        for (let ii = 0; ii < endOffset; ii++) {
          row.push(undefined);
        }
      })
    )
  }
  const maxTimestamps = series[0].timestamps;

  const deathsData = {
    ...jhuDeaths.data,
    ...nytStates.deaths,
    ...nytCounties.deaths,
  };
  const confirmedData = {
    ...jhuConfirmed.data,
    ...nytStates.confirmed,
    ...nytCounties.confirmed,
  };

  const allLocations = Object.keys(deathsData);

  const deaths = {
    rows: deathsData,
    header: maxTimestamps,
    data: [],
  };
  const confirmed = {
    rows: confirmedData,
    header: maxTimestamps,
    data: [],
  };
  return {
    allLocations,
    deaths,
    confirmed,
  }
};

function getData() {
  return Promise.all(
    [
      (
        window.fetch(JHU_DEATHS)
          .then(response => response.text())
          .then(body => parseRawData(body))
          .then(rows => formatJHURows(rows))
      ),
      (
        window.fetch(JHU_CONFIRMED)
          .then(response => response.text())
          .then(body => parseRawData(body))
          .then(rows => formatJHURows(rows))
      ),
      (
        window.fetch(NYT_STATES)
          .then(response => response.text())
          .then(body => parseRawData(body))
          .then(rows => formatNYTRows(rows))
      ),
      (
        window.fetch(NYT_COUNTIES)
          .then(response => response.text())
          .then(body => parseRawData(body))
          .then(rows => formatNYTRows(rows, true))
      ),
    ]).then(mergeFormattedData);
}

function getLastValue(row, lambda=(val) => val !== undefined) {
  let value;
  let offset = 1;
  while (true) {
    value = row[row.length - offset];
    if (lambda(value)) {
      break;
    }
    offset += 1;
  }
  return value;
}

