import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

export default class CheckSheet extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedValues: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);

    const state = {
      visible: false,
      activeTab: 'countries',
    };
    props.options.forEach(option => state[option.key] = false);
    props.selectedValues.forEach(value => state[value] = true);

    this.state = state;

    this.onClickTab = this.onClickTab.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearSelections = this.clearSelections.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const visible = !this.state.visible;
    if (!visible) {
      const locations = Object.entries(this.state)
        .filter(([name, val]) => val && name !== 'visible' && name !== 'activeTab')
        .map(([name, val]) => name)
      this.props.onSubmit(locations);
    }

    this.setState({
      visible,
    });
  }

  clearSelections() {
    const state = {};
    this.props.options.forEach(option => state[option.key] = false);
    this.setState(state);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.checked;

    this.setState({
      [name]: value
    });
  }

  onClickTab(evnt) {
    this.setState({
      activeTab: evnt.target.dataset.name,
    });
  }

  getOptionsByName(options) {
    const optionsByName = {
      'countries': [],
      'states': [],
      'counties': [],
    };
    const sortedOptions = options.sort((a, b) => a.key.localeCompare(b.key));
    sortedOptions.forEach(option => {
      const {key} = option;

      if (!key) {
        return;
      }

      if ((key === 'US') || !key.startsWith('US')) {
        optionsByName['countries'].push(option);
      } else {
        const numSections = key.split(' - ').length;
        if (numSections === 2) {
          optionsByName['states'].push(option);
        } else if (numSections === 3) {
          optionsByName['counties'].push(option);
        }
      }
    });
    return optionsByName;
  }

  render() {
    const {
      heading,
      options,
    } = this.props;

    const {
      activeTab,
      visible,
    } = this.state;

    const hiddenClassName = 'CheckSheet--hidden';

    const containerClassName = visible ? '' : hiddenClassName;

    const optionsByName = this.getOptionsByName(options);

    return (
      <div className={'CheckSheet'}>
        <div className={'clearfix'}>
          <div className={'left'}>
            <div className={'CheckSheet-heading'}>{heading}</div>
          </div>
          <div className={'right'}>
            <button onClick={this.onSubmit}>
              {visible ? 'Update' : 'Select Locations'}
            </button>

            {visible ? (
              <button className={'clear-button'} onClick={this.clearSelections}>
                Clear selections
              </button>
            ) : null}
          </div>
        </div>
        <div className={containerClassName}>
          <div className={'center'}>
            <div className={'CheckSheet-tab-bar'}>
              {
                [
                  ['countries', 'Countries / Provinces'],
                  ['states', 'U.S. States / Territories'],
                  ['counties', 'U.S. Counties'],
                ].map(([name, title]) => (
                  <span
                    className={`
                      CheckSheet-tab
                      ${name === activeTab ? 'CheckSheet-tab--active' : ''}
                    `}
                    data-name={name}
                    onClick={this.onClickTab}
                    key={`CheckSheet-tab-${name}`}>
                    {title}
                  </span>
                ))
              }
            </div>
          </div>
          <div className={`
            CheckSheet-sheet-container
            ${activeTab === 'counties' ? 'CheckSheet--two-cols' : ''}
          `}>
            {
              ['countries', 'states', 'counties'].map(name => (
                <div
                  className={`
                    CheckSheet-sheet
                    ${name === activeTab ? '' : hiddenClassName}
                  `}
                  key={`CheckSheet-sheet-${name}`}>
                  {optionsByName[name].map(option => (
                    <label key={option.key}>
                      <input
                        checked={this.state[option.key]}
                        name={option.key}
                        onChange={this.handleInputChange}
                        type={'checkbox'} />
                      {option.key}
                    </label>
                   ))}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

